<template>
  <v-data-table
    :headers="headers"
    :items="items"
    :loading="loading"
    :options.sync="paginate"
    :server-items-length="paginate.total"
    :footer-props="dataTableFooter">
    <template v-slot:[`item.code`]="{ item }">
      {{ item.code }}
      <v-btn
        v-clipboard:copy="item.code"
        v-clipboard:success="onCopy"
        v-clipboard:error="onCopyError"
        icon
        x-small>
        <v-icon>mdi-content-copy</v-icon>
      </v-btn>
    </template>
    <template v-slot:[`item.amount`]="{ item }">
      {{ discountCompute(item) }}
    </template>
    <template v-slot:[`item.minPrice`]="{ item }">
      {{ showFullPriceFormat(item.minPrice) }}
    </template>
    <template v-slot:[`item.startDate`]="{ item }">
      {{ dateCompute(item.dateRange.startDate) }}
    </template>
    <template v-slot:[`item.endDate`]="{ item }">
      {{ dateCompute(item.dateRange.endDate) }}
    </template>
    <template v-slot:[`item.status`]="{ item }">
      {{ statusCompute(item.status) }}
    </template>
    <template v-slot:[`item.campaignType`]="{ item }">
      {{ getCampaign(item.campaignType) }}
    </template>
    <template v-slot:[`item.usedCount`]="{ item }">
      {{ usedCountCompute(item) }}
    </template>
    <template v-slot:[`item.broadcast`]="{ item }">
      <v-btn
        icon
        @click="broadcastClicked(item)">
        <v-icon>mdi-message</v-icon>
      </v-btn>
    </template>
    <template v-slot:[`item.view`]="{ item }">
      <v-btn
        icon
        :to="{ name: 'PromotionInfo', params: { promotionId: item.id, promotionCode: item.code } }">
        <v-icon>mdi-eye</v-icon>
      </v-btn>
    </template>
    <template v-slot:[`item.edit`]="{ item }">
      <v-btn
        icon
        @click="editClicked(item)">
        <v-icon>mdi-square-edit-outline</v-icon>
      </v-btn>
    </template>
    <template v-slot:[`item.delete`]="{ item }">
      <v-btn
        icon
        @click="deleteClicked(item)">
        <v-icon>mdi-delete</v-icon>
      </v-btn>
    </template>
  </v-data-table>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    items: {
      type: Array,
      required: true
    },
    value: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      dataTableFooter: {
        itemsPerPageText: 'จำนวนแสดงต่อหน้า',
        itemsPerPageOptions: [5, 10, 15],
        showFirstLastPage: true
      },
      headers: [
        {
          text: 'โค้ด',
          align: 'center',
          sortable: false,
          value: 'code'
        },
        {
          text: 'ส่วนลด',
          align: 'center',
          sortable: false,
          value: 'amount'
        },
        {
          text: 'ยอดซื้อขั้นต่ำ',
          align: 'center',
          sortable: false,
          value: 'minPrice'
        },
        {
          text: 'วันเริ่ม',
          align: 'center',
          sortable: false,
          value: 'startDate'
        },
        {
          text: 'วันสิ้นสุด',
          align: 'center',
          sortable: false,
          value: 'endDate'
        },
        {
          text: 'สถานะ',
          align: 'center',
          sortable: false,
          value: 'status'
        },
        {
          text: 'Campaign',
          align: 'center',
          sortable: false,
          value: 'campaignType'
        },
        {
          text: 'การใช้งาน',
          align: 'center',
          sortable: false,
          value: 'usedCount'
        },
        {
          text: 'Broadcast',
          align: 'center',
          sortable: false,
          value: 'broadcast'
        },
        {
          text: 'รายละเอียด',
          align: 'center',
          sortable: false,
          value: 'view'
        },
        {
          text: 'แก้ไข',
          align: 'center',
          sortable: false,
          value: 'edit'
        },
        {
          text: 'ลบ',
          align: 'center',
          sortable: false,
          value: 'delete'
        }
      ],
      campaignTypes: [
        { text: 'Brand', value: 'brand_campaign' },
        { text: 'Member', value: 'member_campaign' },
        { text: 'First Online Purchase', value: 'first_online_purchase' }
      ]
    }
  },
  computed: {
    paginate: {
      get () {
        return this.value
      },
      set (_value) {
        const value = { ..._value, page: _value.page || 1 }
        this.$emit('input', value)
      }
    }
  },
  methods: {
    ...mapActions({
      setSnackbar: 'Components/setSnackbar',
      setModal: 'Components/setModal',
      setLoading: 'Components/setLoading'
    }),
    getCampaign (item) {
      return this.campaignTypes.find((v) => v.value === item)?.text || item
    },
    dateCompute (date) {
      return date ? this.$dayjs(date).format('DD MMM YYYY | HH:mm') : '-'
    },
    discountCompute (promotion) {
      return promotion.discountType === 'amount'
      ? `${this.showFullPriceFormat(promotion.discountNumber)} บาท`
      : `${promotion.discountNumber} %`
    },
    showFullPriceFormat (number) {
      const num = parseFloat(number)
      if (num === 0 || num) {
        return parseFloat(num).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      }

      return '-'
    },
    statusCompute (status) {
      return status === 'unuse' ? 'ยังไม่ได้ใช้งาน' : 'ใช้งานแล้ว'
    },
    usedCountCompute (item) {
      const min = item.usedCount
      const max = item.amount !== -1 ? item.amount : '-'

      return `${min} / ${max}`
    },
    broadcastClicked (promotion) {
      this.$router.push({ name: 'Broadcast', query: { code: promotion.code } })
    },
    editClicked (promotion) {
      this.$emit('edit', promotion)
    },
    deleteClicked (promotion) {
      this.$emit('delete', promotion)
    },
    onCopy (code) {
      this.setSnackbar({
        value: true,
        message: `คัดลอก ${code.text} แล้ว`,
        type: 'success'
      })
    },
    onCopyError () {
      this.setSnackbar({
        value: true,
        message: 'เกิดข้อผิดพลาดระหว่างคัดลอก',
        type: 'error'
      })
    }
  }
}
</script>
